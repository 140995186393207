import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import styled from 'styled-components'
import Header from "./header"
import Footer from './footer'

const FtSticky = styled.footer`
  position: fixed;
  background-color: #9ed9c6;
  z-index: 5;
  bottom: 0;
  left: 0;
  right: 0;
`

const MainStyle = styled.main`
    height: 800;
    padding: 2rem 2rem 2rem 2rem,
    paddingTop: 0,
`;

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <Header siteTitle={data.site.siteMetadata.title} />
        <MainStyle>
          <div className="container">
        {children}
          </div>
        </MainStyle>
        <FtSticky>
          <Footer/>
        </FtSticky>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
