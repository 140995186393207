import { Link } from "gatsby"
import React, { Component } from "react";
import styled from 'styled-components'

const Nav = styled.nav`
  flex-grow: 1;
  display: flex;
  align-items: center;
`;
const Navlink = styled.li`
  border: none;
  display: inline-block;
  padding: 5px 5px;
}`;
const StyleLink = styled(Link)`
text-decoration: none;
font-family: BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
font-size: 1.5rem;
color: black;
&:hover { color: #58a89e; border: 1px solid #58a89e }
`;
const ActiveLink = styled(StyleLink)`
  color: blue;
`;


class Navigation extends Component {
    state = {
      page : 'home'
    };
    handleClick = e => {
      this.setState({page: e })
    }


  render() {
  return (
<Nav>
  <ul>
  <Navlink>
   {(this.state.click)?
   (<ActiveLink to="/" click={this.state.click}  onClick={this.handleClick} >Home </ActiveLink>)
   : (<StyleLink to="/" click={this.state.click}  onClick={this.handleClick} >Home </StyleLink>)}
  </Navlink>
  <Navlink>
  <StyleLink to="/about/">
    About
  </StyleLink>
  </Navlink>
  <Navlink>
  <StyleLink to="/project/">Project</StyleLink>
  </Navlink>
  <Navlink>
  <StyleLink to="/blog/">blog</StyleLink>
  </Navlink>
  </ul>
</Nav>
)
}
}
export default Navigation