
import React from "react"
import SocialLink from "./socialLink"
import styled from 'styled-components'

const FtText = styled.p`
  text-align: center;
`;
const Footer = () => {

  return(
  <div className="container">
    <SocialLink></SocialLink>
    <FtText>
      © Charlene Liu {new Date().getFullYear()}.  All rights reserved.
    </FtText>
  </div>
  )
}

export default Footer;