import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { fas } from '@fortawesome/free-solid-svg-icons'
import styled from 'styled-components'
library.add(fab, fas)

const IconStyle = styled.span`
  &:hover { color: #58a89e;}
`;



const SocialLink = () => (
<div style={{
  display :`flex`,
  "flexDirection":  `row`,
  "justifyContent" : `center`,
  margin: `0.45rem 0.45rem`,
}}>

<a href='https://github.com/hibow'
target='blank'
>
<FontAwesomeIcon icon={["fab", "github"]} style={{color:"#000000"}} size="2x" />
</a>
<p style={{marginLeft: '1.45rem'}}>
</p>
<a href='https://www.linkedin.com/in/charlenealiu/'
target='blank'
>
<FontAwesomeIcon icon={["fab", "linkedin"]} style={{color:"#000000"}} size="2x" />
</a>
<p style={{marginLeft: '1.45rem'}}>
</p>
<a href='mailto:charlene.a.liu@gmail.com'
>
<FontAwesomeIcon icon={["fas", "envelope-square"]} color="#000000" size="2x" />
</a>
</div>
)

export default SocialLink