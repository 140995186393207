import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import Navigation from "./navigation"
import styled from 'styled-components'
import Logo from './logo'

const HeaderStyle = styled.header`
   background: #EAFAF1;
   marginBottom: 1.45rem;
`;

const HeaderContainer = styled.div`
  padding: 1.45rem 1.0875rem;
  display: flex;
  justifyContent: center;
`;

const LinkDiv = styled.div`
textDecoration: none;
display: block;
`;
const SpaceDiv = styled.div`
  flex-grow: 3;
`;

const Header = ({ siteTitle }) => (
  <HeaderStyle
  >
    <div className="container">
    <HeaderContainer>
      <LinkDiv>
        <Link
          to="/"
        >
          <Logo></Logo>
        </Link>
        </LinkDiv>
        <SpaceDiv />
      <Navigation/>
  </HeaderContainer>
  </div>
  </HeaderStyle>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
